import css from 'styled-jsx/css'
import { dynamicCssSize } from '../../../lib/utils/constants/PagesConstants'

export default css.global`

.footerLinksSection{
    display: none;
}

.footerLinksSectionV2{
    display: none;
    font-family: var(--font-primary);
}

@media screen and (max-width: 1024px) {
    .footerLinksSection{
        position: relative;
        margin-top: 10px;
        background-color: #e8e8e8;
        padding: 0 30px 10px 30px;
        display: flex;
        flex-direction: column;
    }
    
    .footerUList {
        padding: 0;
        list-style-type: none;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
    
    .footerUList a{
        text-decoration: none;
        text-transform: uppercase;
    }
    
    .footerUList li {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: -0.12px;
        color: #000;
    }
    
    .footerUList li a {
        color: #616161;
    }
    
    .headingFooterLink {
        color: #000;
        text-transform: uppercase;
        text-align: center;
        font-family: var(--font-nunito), sans-serif;
        font-size: 18px;
        font-weight: 300;
        opacity: 0.7;
        padding: 18px;
        max-width: fit-content;
        margin: 0 auto;
    }

    .readMoreLinks {
        font-size: 12px;
        color: #000;
        position: absolute;
        right: 30px;
        top: 20px;
    }

    .readMoreImage {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .readMoreDiv {
        color: #000;
        font-size: 12px;
        position: absolute;
        top: 2px;
        right: 15px;
        display: flex;
        text-transform: uppercase;
    }
       
    .footerLinksSectionV2{
        position: relative;
        background-color: #f1f1f1;
        padding: 0 30px 10px 30px;
        display: flex;
        flex-direction: column;
    }
    
    .footerUListV2 {
        padding: 0;
        margin: 0;
        list-style-type: none;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
    
    .footerUListV2 a{
        text-decoration: none;
        text-transform: uppercase;
    }
    
    .footerUListV2 li {
        margin-bottom: 12px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.1px;
    }
    
    .footerUListV2 li a {
        color: #282727;
    }
    
    .headingFooterLinkV2 {
        font-size: ${dynamicCssSize(31,10)};
        font-weight: 900;
        font-style: italic;
        letter-spacing: 0.64px;
        text-align: center;
        color: #540979;
        margin: 20px 0px;
        text-transform: uppercase;
    }

    .readMoreLinksV2 {
        font-size: 12px;
        color: #000;
        position: absolute;
        right: 30px;
        top: 20px;
    }

    .readMoreImageV2 {
        position: relative;
        width: 18px;
        height: 18px;
    }

    .readMoreDivV2 {
        color: rgb(84 9 121);
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }
    
    .lineParentV2 {
        display: flex;
        align-items: end;
        margin: 23px 0px;
        padding: 0;
        transform: rotateX(-45deg);
    }
    .leftLineV2 {
        width: 100%;
        height: 10px;
        border-bottom: 2px solid #540979;
        border-right: 15px solid #540979;
        transform: skewX(30deg);
    }
    .rightLineV2 {
        width: 100%;
        height: 10px;
        border-bottom: 2px solid #540979;
        border-left: 15px solid #540979;
        transform: skewX(-30deg);
    }
}
`